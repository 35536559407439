<script setup lang="ts">
import LayoutBanner from '@/components/layout/LayoutBanner.vue';
import AgeGateWrapper from '@/components/ageGate/AgeGateWrapper.vue';
import { usePage } from '@/composables/usePage';
import LayoutFooter from '@/components/layout/LayoutFooter.vue';
import { parse } from '@/helpers/parse';
import type { GlobalPage } from '@/types/Page';
import ThemeProvider from '@/components/ThemeProvider.vue';

withDefaults(
  defineProps<{
    disableHeadline?: boolean;
    showAgeGate?: boolean;
    fullWidth?: boolean;
    paddingBottom?: boolean;
  }>(),
  {
    disableHeadline: true,
    showAgeGate: false,
    fullWidth: false,
    paddingBottom: true
  }
);

const globalPage = usePage<GlobalPage>('global');
const page = usePage();
</script>

<template>
  <ThemeProvider>
    <div class="flex flex-col min-h-screen">
      <div v-if="showAgeGate">
        <AgeGateWrapper />
      </div>

      <LayoutBanner />

      <main id="main" class="flex-shrink-0 flex-grow pt-10" :class="{ 'pb-20': paddingBottom }">
        <div :class="{ 'container': !fullWidth }">
          <div class="flex flex-col justify-center w-full">
            <slot name="header">
              <div class="space-y-4 mb-4">
                <div v-if="!disableHeadline" class="text-center space-y-4">
                  <h1 v-if="globalPage.headline" v-html="globalPage.headline" />
                  <div v-if="globalPage.subHeadline" class="" v-html="globalPage.subHeadline" />
                </div>

                <div v-html="parse(page.content.header)" />
              </div>
            </slot>
            <div class="w-full">
              <slot />

              <div v-if="page.content.footer" v-html="parse(page.content.footer)" />
            </div>
            <div class="footerSubtext text-center">
              <slot name="footerSubtext" />
            </div>
          </div>
        </div>
      </main>

      <LayoutFooter />
    </div>
  </ThemeProvider>
</template>

<style>
.footerSubtext p {
  font-size: 0.8rem !important;
}
</style>
