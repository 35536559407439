import { Model } from '@/models/Model';
import { plainToInstance } from 'class-transformer';

export class Redeem extends Model {
  mechanic?: string;
  isWinner?: boolean;
  path?: string;
  prize?: string;
  redeemedAt?: Date;
  redemption?: any;
  status?: string;
  createdAt!: Date;
  updatedAt!: Date;

  static fromApi(data: any) {
    const instance = plainToInstance(Redeem, data);

    return instance;
  }
}
