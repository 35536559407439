<script setup lang="ts">
import { useAppStore } from '@/stores/app';
import { storeToRefs } from 'pinia';
import CircleSpinner from '@/components/CircleSpinner.vue';
import TestBanner from '@/components/TestBanner.vue';
import { watch } from 'vue';
import * as gtag from 'vue-gtag';
import AudioPlayer from '@/components/AudioPlayer.vue';
import { useMinimal } from '@/composables/useMinimal';
import { useHead } from '@unhead/vue';

const appStore = useAppStore();
const { loading, activity } = storeToRefs(appStore);
const minimal = useMinimal();

useHead({
  script: [
    {
      src: "https://maps.googleapis.com/maps/api/js?key=AIzaSyC5zZqjakFv04XrAyxEbOtZCCAYxUd0S5w&libraries=places",
      defer: true
    }
  ],
})

watch(activity, (activityVal) => {
  if (!activityVal?.frontend.tracking.googleAnalytics) return;

  gtag.setOptions({
    config: { id: activityVal.frontend.tracking.googleAnalytics },
  });

  gtag.bootstrap().then(() => {
    console.debug('Gtag loaded');
  });
});
</script>

<template>
  <TestBanner v-if="!minimal" />
  <el-config-provider size="large">
    <RouterView v-if="!loading" :key="$route.path" />

    <Transition name="fade">
      <div v-if="loading" class="absolute top-0 left-0 w-full h-min-100vh bg-white">
        <CircleSpinner />
      </div>
    </Transition>
  </el-config-provider>

  <AudioPlayer v-if="!minimal" />
</template>

<style></style>
