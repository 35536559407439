import { plainToInstance } from 'class-transformer';
import { Model } from '@/models/Model';

export class User extends Model {
  // "address": {},
  // "_id": "66b029bc7e3e6a339d76eab7",
  // "platform": "myy",
  // "mobile": "+61468389105",
  // "acceptedTerms": true,
  // "confirmed": true,
  // "profileComplete": true,
  // "createdAt": "2024-08-05T01:24:12.969Z",
  // "updatedAt": "2024-08-16T00:29:20.868Z",
  // "__v": 1,
  // "dateOfBirth": "2021-05-17T00:00:00.000Z",
  // "email": "ben.h@homeofpmg.com",
  // "emailOriginal": "ben.h@homeofpmg.com",
  // "firstName": "Ben",
  // "lastName": "Hepburn",
  // "title": "mr",
  // "accessGroups": [
  //   "super_secret_activities"
  // ],
  // "accessCodes": [],
  isTemporary!: boolean;
  // "age": 3,
  // "id": "66b029bc7e3e6a339d76eab7"

  static fromApi(data: any) {
    return plainToInstance(User, data);
  }
}
