import { plainToInstance } from 'class-transformer';
import { type Component, defineAsyncComponent, markRaw } from 'vue';
import type { FileUpload } from '@/models/FileUpload';
import type { Logic } from '@/types/Logic';
import { FieldDisplayConditionType } from '@/types/FieldDisplayConditions/FieldDisplayCondition';
import { solveFieldDisplayConditions } from '@/types/FieldDisplayConditions/solveFieldDisplayConditions';

export enum FieldType {
  NFC = 'nfc',
  FILE_UPLOAD = 'file_upload',
  BUTTONS = 'buttons',
  STAFF_TAP = 'staff_tap',
  SCAN = 'scan',
  STRING = 'string',
  MOBILE = 'mobile',
  NUMBER = 'number',
  CHECKBOX = 'checkbox',
  RADIO = 'radio',
  SELECT = 'select',
  DATE = 'date',

  WYSIWYG = 'wysiwyg',
}

const fieldMap: Record<FieldType, Component> = {
  [FieldType.CHECKBOX]: defineAsyncComponent(() => import('@/components/entryForm/fields/FieldCheckbox.vue')),
  [FieldType.BUTTONS]: defineAsyncComponent(() => import('@/components/entryForm/fields/FieldButtons.vue')),
  [FieldType.FILE_UPLOAD]: defineAsyncComponent(() => import('@/components/entryForm/fields/FieldFileUpload.vue')),
  [FieldType.SELECT]: defineAsyncComponent(() => import('@/components/entryForm/fields/FieldSelect.vue')),
  [FieldType.STRING]: defineAsyncComponent(() => import('@/components/entryForm/fields/FieldString.vue')),
  [FieldType.NUMBER]: defineAsyncComponent(() => import('@/components/entryForm/fields/FieldNumber.vue')),
  [FieldType.MOBILE]: defineAsyncComponent(() => import('@/components/entryForm/fields/FieldMobile.vue')),
  [FieldType.DATE]: defineAsyncComponent(() => import('@/components/entryForm/fields/FieldDate.vue')),
  [FieldType.WYSIWYG]: defineAsyncComponent(() => import('@/components/entryForm/fields/FieldWysiwyg.vue')),
};

type ValidationFunction = () => Promise<string>;
type StageFunction = (input: any) => Promise<any>;

export type FormStages = {
  preAuthenticate: StageFunction[];
  postAuthenticate: StageFunction[];
};

export interface FieldDisplayRuleCondition<ParametersType = {}> {
  type: FieldDisplayConditionType;
  parameters: ParametersType;
}

type FieldDisplayRule = {
  conditions: Logic<FieldDisplayRuleCondition>;
  result: 'hide' | 'disable' | 'required' | 'optional';
};

export type FieldHint = {
  content: string;
};

export class FormField<ParametersType = any> {
  label?: string;
  placeholder?: string;
  hint?: FieldHint;
  icon?: FileUpload;
  type!: FieldType;
  path!: string;
  required!: boolean;
  display!: {
    spanMobile: number;
    spanDesktop: number;
    rules: FieldDisplayRule[];
  };
  parameters!: ParametersType;

  validations: ValidationFunction[] = [];
  stages: FormStages = {
    preAuthenticate: [],
    postAuthenticate: [],
  };

  get component() {
    const comp = fieldMap[this.type];
    return comp ? markRaw(comp) : undefined;
  }

  static fromApi(data: any) {
    return plainToInstance(FormField, data);
  }

  registerStageFunction(stage: keyof FormStages, fn: StageFunction) {
    this.stages[stage].push(fn);
    return this;
  }

  registerValidation(fn: ValidationFunction) {
    this.validations.push(fn);
    return this;
  }

  clearStage(stage: keyof FormStages) {
    this.stages[stage] = [];
    return this;
  }

  clearValidation() {
    this.validations = [];
    return this;
  }

  async runValidations() {
    return Promise.all(this.validations.map((validation) => validation()));
  }

  async runStage(stage: keyof FormStages, input: any) {
    let data = input;
    for (const stageFn of this.stages[stage]) {
      data = await stageFn(data);
    }
    return data;
  }

  isRequired() {
    return this.display.rules.some((rule) => {
      return solveFieldDisplayConditions(rule.conditions) && rule.result === 'required';
    });
  }

  shouldDisplay() {
    if (this.display.rules.length === 0) return true;

    return !this.display.rules.some((rule) => {
      return solveFieldDisplayConditions(rule.conditions) && rule.result === 'hide';
    });
  }
}
